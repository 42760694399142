<template>
  <div>
    <ch-layout page search>

      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px',}">
          </ch-search>
        </div>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:amount="scope">
            <span>
              {{scope.row.amount}}
              <el-tooltip class="item" effect="dark" content="保证好服务质量！" placement="top">
                <ch-icon v-if="scope.row.payType===3" style="font-size: 20px;margin-right: 5px" name="payTypeBefore" />
              </el-tooltip>
            </span>
          </template>
          <template v-slot:handle="scope">
              <ch-button type="link" v-if="scope.row.status===7" @click="toAccept(scope.row)">接单</ch-button>
              <ch-button type="link" @click="toPage(scope.row.id)">详情</ch-button>
              <ch-button type="link" v-if="(scope.row.status===7||scope.row.status===1||scope.row.status===8)&&scope.row.serverStaffName" @click="openChange(scope.row)">更换人员</ch-button>
              <ch-button type="delete" v-if="scope.row.canCancel" @click="openCancel(scope.row.id)">取消</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page" :sizeDe="20"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <change-service ref="changeService" :get-list-function="getTableList" />
    <take-orders ref="takeOrders" :get-list-function="getTableList" />
  </div>
</template>

<script>
import changeService from "@/views/orderManagement/serviceOrders/changeService.vue";
import takeOrders from "@/views/orderManagement/serviceOrders/takeOrders.vue";
export default {
  components: {
    changeService,takeOrders
  },
  data() {
    return {
      searchForm:{
        goodsName: "",
        orderNo: "",
        staffName: "",
        status: '',
        userName: "",
        userPhone: ""
      },
      tableList: [],
      isTableLoading: false,
      status:['待支付','待服务','已取消','已评价','已完成','服务中','待验收','待接单','待签到','申诉中']
    }
  },
  computed: {
    searchOption: function () {
      return [
        {type: 'input', label: '服务订单号：', prop: 'orderNo', placeholder: "请输入服务订单号",labelWidth: '80px'},
        {type: 'input', label: '用户姓名：', prop: 'userName', placeholder: "请输入用户姓名",},
        {type: 'input', label: '用户手机号：', prop: 'userPhone', placeholder: "请输入用户手机号",labelWidth: '80px'},
        {type: 'input', label: '服务名称：', prop: 'goodsName', placeholder: "请输入服务名称",},
        {type: 'input', label: '服务人员：', prop: 'staffName', placeholder: "请输入服务名称",},
        {
          type: "select", label: "状态：", prop: "status", placeholder: "请选择状态",labelWidth: '44px',
          name: "label", value: "value", option: [
            {value: 0, label: "待支付"},
            {value: 1, label: "待服务"},
            {value: 2, label: "已取消"},
            {value: 3, label: "已评价"},
            {value: 4, label: "已完成"},
            {value: 5, label: "服务中"},
            {value: 6, label: "待验收"},
            {value: 7, label: "待接单"},
          ]
        },
        {
          type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]
        },

      ]
    },
    tableOption: function () {
      return [
        {column: "type", prop: "", type: "selection", width: "25px"},
        {column: "type", label: "序号", prop: "", type: "index", width: "70",},
        {column: "text", label: "服务订单号", prop: "serverNumber",showOverflowTooltip:true,},
        {column: "text", label: "用户姓名", prop: "userName",showOverflowTooltip:true,},
        {column: "text", label: "手机号码", prop: "telNumber",showOverflowTooltip:true,},
        {column: "text", label: "服务地址", prop: "_detailInfo",showOverflowTooltip:true, width: 200},
        {column: "text", label: "服务名称", prop: "goodsName",showOverflowTooltip:true,},
        {column: "slot", label: "本单金额(/元)", slotName: "amount",showOverflowTooltip:true,},
        {column: "text", label: "下单时间", prop: "createTime",showOverflowTooltip:true,},
        {column: "text", label: "服务开始时间", prop: "startTime",showOverflowTooltip: true,},
        {column: "text", label: "服务人员", prop: "serverStaffName",showOverflowTooltip:true,},
        {column: "text", label: "状态", prop: "_status",},
        {column: "slot", label: "操作", slotName: "handle", width: "180", fixed: 'right'},
      ];
    },
  },
  activated() {
    this.getTableList()
  },
  methods: {
    // 查询
    searchHandle() {
      this.getTableList()
    },
    getTableList(params=this.searchForm) {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/order/serviceList",params).then((res) => {
        let row = res.rows
        this.tableList = row.map((item) => {
          return {
            ...item,
            _useNumber: item.useNumber +'/'+ item.number,
            _status: this.status[item.status],
            _detailInfo: item.provinceName + item.cityName + item.countyName + item.detailInfo
          }
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    // 接单
    toAccept(row){
      if(row.serverStaffName){
        this.$confirm('是否接单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$curl.post('/hm/order/serviceAccept/'+row.id).then(()=>{
            this.$message({
              type: 'success',
              message: '接单成功!'
            });
            this.getTableList()
          })
        })
      }else {
        let rows = {
          serviceOrderId: row.id,
          localDateTimeStart:row.startTime,
          goodsId:row.goodsId,
          // serverStaffName:row.serverStaffName,
          oldStaffId:row.serviceStaffId
        }
        this.$refs.takeOrders.open(rows)
      }
    },
    // 跳转详情
    toPage(orderId){
      this.$router.push({ name: "serviceDetail", query: { orderId } });
    },
    // 更换人员
    openChange(row){
      let rows = {
        serviceOrderId: row.id,
        localDateTimeStart:row.startTime,
        goodsId:row.goodsId,
        serverStaffName:row.serverStaffName,
        oldStaffId:row.serviceStaffId
      }
      this.$refs.changeService.openChange(rows)
    },
    // 取消预约
    openCancel(id){
      this.$confirm('是否取消订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$curl.get('/hm/order/cancel/'+id).then((res)=>{
          if(res.code===200){
            this.$message({
              type: 'success',
              message: '取消成功!'
            })
            this.getTableList()
          }
        })
      })
    },
  }
}

</script>

<style scoped lang="scss">

</style>